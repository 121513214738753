import { VITE_SESSION_DOMAIN } from './environment';

export const getCookieValue = (key) => {
    const cookieValue = document.cookie.match(`(^|;)?${key}=([^;]*)(;|$)`);

    return cookieValue ? decodeURIComponent(cookieValue[2]) : null;
};

export const setCookie = (key, value, expirationDay) => {
    let expires = '';

    if (expirationDay) {
        const date = new Date();
        date.setTime(date.getTime() + (expirationDay * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${key}=${value || ''}${expires}; path=/; domain=${VITE_SESSION_DOMAIN}`;
};
